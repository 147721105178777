<template>
  <div class="content-box">
    <div class="container">
      <div class="search-action">
        <div class="inputs_filter">
          <el-input
            placeholder="请输入企业名称"
            prefix-icon="el-icon-search"
            class="search-input"
            v-model="search"
            clearable
          >
          </el-input>
          <div class="search-btn" @click="openFullScreen2">立即获取报告</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Info } from "@/common/js/api";

export default {
  data() {
    return {
      info: [],
      id: "",
    };
  },
  created() {
    this.id = JSON.parse(localStorage.getItem("user")).id;
    this.Info();
  },
  methods: {
    Info() {
      Info({
        id: this.id,
      })
        .then((res) => {
          this.info = res.data;
        })
        .catch(() => {});
    },
    openFullScreen2() {
      if (this.info.provider) {
        this.$router.push({
          path: "/member2/pg",
        });
      } else {
        this.$confirm("请先注册服务商", "提示", {
          confirmButtonText: "注册",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let routeData = this.$router.resolve({
              path: "/service_s",
            });
            window.open(routeData.href, "_blank");
          })
          .catch(() => {});
      }
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      // setTimeout(() => {
      //   loading.close();

      // }, 3000);
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 945px;
  margin: 20% auto;
}
/deep/.el-input__inner {
  border: 0;
}
</style>